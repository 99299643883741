a {
  text-decoration: unset;
  color: darkslateblue;
}

table {
  width: 100% !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background: transparent;

}


/* Track */
::-webkit-scrollbar-track {
  background: transparent;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffec5d;
  height: 8px;
}

.success_msg {
   color: #32481e;
  display: flex;
  background: #cfffc6;
  padding: 5px;
  border-radius: 10px;
  align-items: center;
  text-align: left;
  width:84%
}
.success_msg > small { margin-left: 5px }

.cover>img {
  width: 100vw;
}

.product_detail_img {
  width: 300px;
  border-radius: 20px;
  margin-bottom: -4px
}
.flex-wrp{
  display:flex;
  flex-wrap: wrap;
}
.img-wrapper {
  width: 300px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  min-height: 250px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.product_detail_img_thumb {
  text-align: center;
  margin-top: 12px !important;
}

.product_detail_img_thumb>img {
  width: 55px;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.product_detail_img_thumb>img:hover {
  border: 3px solid #92a5ff;
}

.price_tag {
  display: inline;
  color: #ff6666;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.pm-btn {
  margin-left: 3px !important;
  margin-right: 3px !important;
  padding: 2px !important
}

.pm-icon {
  fill: currentColor;
  width: 30px;
  height: 30px;
  display: inline-block;
  font-size: 28px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

#quan_inp {
  outline: none;
  border: 1px solid #e4e4e4;
  padding: 4px;
  border-radius: 15px;
  width: 66px;
  font-size: 17px;
  text-align: center;
}

#quan_inp:hover,
#quan_inp:focus {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)
}

#quantity {
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
}

.description {
  background: #fafafa;
  width: 90%;
  padding: 0px;
  border-radius: 15px;
  margin-top: 20px !important;
  overflow-wrap: break-word;
}

.buy-now-btn {
  padding: 10px 20px !important;
  background: #ffe000 !important;
  font-weight: bold !important;
}

.tab {
  font-weight: 800;
}

.tab>span {
  flex-direction: row !important;
}

.tab>span>span {
  text-align: left !important;
  padding: 6px 17px 6px 1px;
}

.tabs {
  padding: 20px;
  color: #6b6b6b;
}

#sm {
  width: 300px;
  margin: 10px auto auto auto;
  background: aliceblue;
  border-radius: 10px;
  padding: 8px;
  color: #080e4e;
}

.basket-btn {
  background: #009dff !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  padding: 10px 20px !important;
}

.cpy-btn {
  background: bisque !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  padding: 10px 20px !important;
}

.pm {
  display: flex;
  align-items: center;
  height: 31px;
  width: 186px;
}


.pm>img {
  height: 31px;
  border: 2px solid #87cefa;
  border-radius: 7px;
  margin-left: 10px;
}

.inputField {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

#form {
  max-width: 900px;
  margin: auto;
}

.mt10 {
  margin-top: 10px;
}

.text-center {
  text-align: center;
}



.btn-icn {
  margin-left: 5px
}

.features-wrp {
  width: 410px
}

.features {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.features>div {
  text-align: center;
  font-weight: 500;
  color: #3f5576;
}

.features>div>img {
  width: 50px;
  display: block;
  margin: auto;
}


#footer {
  margin-top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(216, 217, 212);
  padding: 10px;
  height:100%;
  font-size: 16px;
  color: #615e58 !important
}
#footer ul li a{
  padding: 0px !important;
  color:#615e58 !important;
}

#footer ul li{
  padding:3px !important;
}
#footer #social img{
  width: 23px;
  height: 20px;
  margin: -4px;
    padding: 0px;
}


.pdt-title {
  color: #ff6666;
}

.pdt-company {
  color: #9c9c9c
}

#zoom-img {
  display: none;
  width: 500px;
  height: 500px;
  border-radius: 15px;
  background: aliceblue;
  position: absolute;
  top: 78px;
  left: 650px;
  z-index: 100;
  border: 7px solid #ffffff85;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  display: none;
  width: 200px;
  height: 200px;
  background: #00000021;
}

.order-menu {
  width: 100px;
  margin-top: 20px;
  background: #92a5ff;
  border-radius: 15px;
  transition: height .5s ease-out
}

.order-img-wrapper {
  display: flex;
  justify-content: center;

}

.order-img {
  max-width: 150px;
  height: 100px;
  border-radius: 10px;
  margin-right: 15px;
  box-shadow: 2px 2px 3px 0px rgba(120, 120, 120, 0.6);
}

.hr-light {
  border: none;
  height: 1px;
  background: #e0e0e0;
  color: #e0e0e0;
}

#pdt-code {
  background: aliceblue;
  padding: 3px 9px;
  border-radius: 30px;
  font-weight: bold;
  color: #000369;
}

#order-btn {
  font-weight: bold !important;
  padding: 8px 8px !important;
  margin-top: 8px !important;
  box-shadow: none;
}

.cat-cover{
  width:100%
}

.d-non {
  display: none !important;
}

.footer-logo {
  height: 24px;
  margin-right: 7px;
}

.paym {
  color: gray;
  width: 260px;
  margin-left: 50px;
  margin-top: 10px;
  text-align: center;
}

.paym>img {
  height: 16px;
  margin-right: 10px;
  margin-top: 5px;
}

#home {
  height: 380px;
}

#home-title {
  position: absolute;
  color: #e64100;
  font-size: 60px;
  margin-left: 30px;
}

#home-subtitle {
  position: absolute;
  margin-top: 195px;
  margin-left: 40px;
  color: #ff4800;
}

#home-img-1 {
  float: right;
}

#man {
  margin-top: 240px;
  margin-left: 100px;
  width: 90px;
  opacity: 0.9;
}

#box {
  position: absolute;
  margin-top: 30px;
  width: 100px;
  margin-left: 300px;
}

#card {
  width: 100px;
  margin-left: 110px;
  margin-top: 220px;
  position: absolute;
}

#mob {
  margin-left: 381px;
}

.cats {
  width: 86%;
  margin: auto;
}

.itm {
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 180px;
  display: block;
  margin: 10px;
  border-radius: 20px;
  margin: 5px auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.itm>span {
  top: 6%;
  left: 6%;
  position: absolute;
  font-weight: 500;
  z-index: 1;
  background: white;
}

.itm>img {
  bottom: 4px;
  max-width: 120px;
  max-height: 140px;
  position: absolute;
  right: 5px;
}

.MuiFab-root-190,
.MuiFab-root-197,
.MuiFab-root-125,
.MuiFab-root-132,
.MuiFab-root-61 {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;

}

.cats-title>span {
  font-size: 30px;
  background-color: white;
  padding: 0 10px;
  color: #ffd900;
}

.cats-title {
  width: 100%;
  height: 25px;
  border-bottom: 5px solid #fffeaf;
  text-align: center
}

.pdt-tmb-wrp {
  overflow-x: scroll;
  background: #fafafa;
}

.pdt-tmb-wrp>div {
  display: inline-flex;
}

.pdt-tmb-wrp>div>span {
  width: 141px;
}

.pdt-tmb-wrp::-webkit-scrollbar {
  height: 4px;
  background: #fafafa;
}

.pdt-tmb-wrp:hover::-webkit-scrollbar {
  height: 10px;
  background: #fafafa;
}

.pdt-tmb-wrp::-webkit-scrollbar-thumb {
  background-color: #dedede;
}

.pdt-tmb-wrp::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}


.pdt-thumb {

  border-radius: 10px;
  margin: 10px;
  background: white;
  padding: 10px;
  width: 250px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.pdt-thumb>div>a>img {
  border-radius: 7px;
  max-width: 230px;
  max-height: 230px;
}

.pdt-thumb>small {
  white-space: nowrap;
  width: 230px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdt-thumb>span {
  text-align: center;
  color: #ff6666;
  font-weight: 800;
}

.buy-modal-title {
  padding: 10px !important;
}

.buy-modal-title>h6 {
  font-size: 15px;
  font-weight: 600;
}

@media (max-width:1200px) {
  #home-img-1 {
    width: 33vw;
  }

  #mob {
    margin-left: 230px;
    position: absolute;
    margin-top: 230px;
    width: 100px;
  }

  #home-title {
    position: absolute;
    color: #5e5a7f;
    font-size: 46px;
    margin-left: 10px;
  }

  #home-subtitle {
    position: absolute;
    margin-top: 152px;
    margin-left: 12px;
    color: #6a6689;
  }
}

@media (max-width:1024px) {
  .cats {
    width: 81%;
  }

  .itm {
    width: 120px;
    height: 120px;
  }

  .itm>span {
    font-weight: 400;
  }

  .itm>img {
    max-width: 100px;
    max-width: 90px;
    right: 5px;
  }
}

@media (max-width:770px) {
  #box {
    width: 68px;
    position: absolute;
    margin-top: 0px;
    width: 100px;
    margin-left: 30%;
  }

  .cats-title>span {
    font-size: 26px
  }

  #home-title {
    position: absolute;
    color: #5e5a7f;
    font-size: 36px;
    margin-left: 10px;
  }

  #home-subtitle {
    margin-top: 15%;
  }

  #man {
    margin-top: 31%;
    margin-left: 2%;
  }

  #card {
    display: none;
  }

  #mob {
    margin-left: 3%;
    margin-top: 19%;
  }

  #home-img-1 {
    width: 54%;
  }

  .cats {
    width: 74%;
  }

  .itm {
    width: 80px;
    height: 80px;
  }

  .itm>span {
    font-size: 13px;
  }

  .itm>img {
    max-width: 55px;
    max-height: 65px;
    right: 5px;
  }

  .pdt-thumb {
    border-radius: 10px;
    margin: 10px;
    background: white;
    padding: 5px;
    width: 141px;
  }

  .pdt-thumb>div>a>img {
    border-radius: 7px;
    max-width: 130px;
    max-height: 130px;
  }

  .pdt-thumb>small {
    white-space: nowrap;
    width: 127px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pdt-thumb>span {
    text-align: center;
    color: #ff6666;
    font-weight: 400;
  }
}



@media (max-width:950px) {
  .footer-logo {
    height: 25px;
    margin-right: 5px;
  }

  .pdt-info {
    text-align: center
  }

  #quantity {
    justify-content: center
  }

  .product_detail_img {
    width: 250px
  }

  .img-wrapper {
    width: 250px;
  }

  .features-wrp {
    width: 100%
  }

  .itm {
    width: 90px;
    height: 90px;
  }
}

@media (max-width:450px) {

  .itm>span {
    font-size: 11px;
  }

  .itm>img {
    max-width: 55px;
    max-height: 53px;
    right: 5px;
  }

  #quan_inp {
    font-size: 17px;
    width: 33px;
    margin-right:6px;

  }
  .pm-icon{
    width: 20px;
    margin-right: -5.5px;
    height: 32px;
    margin-left: -9px;
  }

  .cats {
    width: 72%;
  }

  .itm {
    width: 80px;
    height: 80px;
  }

  .footer-logo {
    height: 20px;
    margin-right: 5px;
  }

  .features-wrp {
    width: 100%
  }

  .paym {
    margin-left: 30px;
  }

  #home-title {
    font-size: 26px;
    margin-left: 10px;
  }

  #home-subtitle {
    margin-top: 25%;
    font-size: 16px;
  }

  #man {
    margin-top: 52%;
    margin-left: 2%;
  }

  #box {
    margin-top: 4px;
    width: 72px;
    right: 51px;
  }

  #mob {
    display: none
  }

  #home-img-1 {
    width: 280px;
    position: absolute;
    right: 0;
    margin-top: 60px;
  }

  .cover>img {
    width: 125vw;
  }

  .cats-title>span {
    font-size: 20px;
  }

  .cats-title {
    height: 18px;
  }
}

@media (max-width:320px) {
  .basket-btn {
    margin-top: 10px !important;
  }
  #quan_inp {
    font-size: 16px;
    width: 31px;

  }
  .pm-icon{
    width: 5px;
    margin-right: 0.5px;
    height: 25px;
  }
  .cart-price{
    display:block;
  }

  .cpy-btn {
    margin-top: 10px !important;
  }

  .features {
    width: 285px;
  }

  .features>div {
    font-size: 11px;
  }

  .features>div>img {
    width: 40px
  }

  .itm {
    width: 70px;
    height: 70px;
  }

  .itm>img {
    max-width: 45px;
    max-height: 40px;
  }

  .pdt-thumb {
    margin: 6px;
    background: white;
    padding: 5px;
    width: 130px;
  }

  .pdt-thumb>div>a>img {
    max-width: 120px;
    max-height: 120px;
  }

  .pdt-thumb>small {
    white-space: nowrap;
    width: 120px;
  }

}